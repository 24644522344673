const featured = document.querySelector(".featured");

if (featured) {
  const featuredImage = featured.querySelector(".featured-image");
  const featuredItems = [].slice.apply(
    featured.querySelectorAll(".featured-item")
  );
  const featuredLoader = featured.querySelector(".loader");
  const controls = featured.querySelector(".featured-controls");
  const controlButtons = [].slice.apply(controls.querySelectorAll("button"));

  const featuredProgress = featured.querySelector(".featured-progress");
  let sliderButtons = controlButtons.map(i => i);

  if (sliderButtons.length < 2) {
    featuredProgress.classList.remove("active");
  }

  const activeFeaturedItem = featuredItems.find(item =>
    item.classList.contains("active")
  );
  const img = new Image();
  img.onload = () => {
    featuredImage.classList.add("active");

    featuredImage.style.backgroundImage = `url('${activeFeaturedItem.dataset
      .image}')`;
  };
  img.src = `${activeFeaturedItem.dataset.image}`;

  controlButtons.forEach((button, index) => {
    button.onclick = () => {
      featuredProgress.classList.remove("active");

      featuredProgress.setAttribute("data-active", index);

      controlButtons.forEach((b, i) => {
        if (i === index) b.classList.add("active");
        else b.classList.remove("active");
      });

      featuredItems.forEach((item, i) => {
        if (i === index) item.classList.add("active");
        else item.classList.remove("active");
      });

      featuredImage.classList.remove("active");

      featuredLoader.classList.add("active");

      setTimeout(() => featuredProgress.classList.add("active"), 10);

      const img = new Image();
      img.onload = () => {
        featuredImage.classList.add("active");

        featuredLoader.classList.remove("active");

        featuredImage.style.backgroundImage = `url('${featuredItems[index]
          .dataset.image}')`;
      };
      img.src = `${featuredItems[index].dataset.image}`;
    };
  });

  if (sliderButtons.length > 1) {
    featuredProgress.addEventListener("animationend", function end() {
      featuredProgress.classList.remove("active");

      if (
        parseInt(featuredProgress.dataset.active) !=
        sliderButtons.length - 1
      ) {
        featuredProgress.setAttribute(
          "data-active",
          parseInt(featuredProgress.dataset.active) + 1
        );
      } else {
        featuredProgress.setAttribute("data-active", 0);
      }

      sliderButtons[parseInt(featuredProgress.dataset.active)].click();

      setTimeout(() => featuredProgress.classList.add("active"), 10);
    });
  }
}

const sectionHead = document.querySelector(".section-head");
const sectionHeadImage = sectionHead
  ? sectionHead.querySelector(".section-head-image")
  : null;

if (sectionHead && sectionHeadImage) {
  const img = new Image();
  img.onload = () => {
    sectionHeadImage.classList.add("active");
    sectionHeadImage.style.backgroundImage = `url('${sectionHeadImage.dataset
      .image}')`;
  };
  img.src = `${sectionHeadImage.dataset.image}`;
}

const promotion = document.querySelector(".modal-destaque");

if (promotion) {
  const formImage = promotion.querySelector(".form-image");
  const fileInput = promotion.querySelector('input[type="file"]');

  if (fileInput.files.length > 0) {
    const file = fileInput.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function(e) {
      formImage.src = e.target.result;
    };
  }

  fileInput.onchange = evt => {
    const file = evt.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function(e) {
      formImage.src = e.target.result;
    };
  };
}

const removeButtons = [].slice.apply(
  document.querySelectorAll(".panel-remove")
);

if (removeButtons) {
  removeButtons.forEach(b => {
    b.onclick = evt => {
      if (!confirm("Tem certeza que deseja remover esse item?"))
        evt.preventDefault();
    };
  });
}

// const editButtons = [].slice.apply(document.querySelectorAll(".panel-edit"));

// if (editButtons) {
//   const modal = document.querySelector(".modal-edit");
//   const modalBackdrop = modal.querySelector(".modal-backdrop");
//   const formClose = document.querySelector(".form-close");

//   editButtons.forEach(b => {
//     b.onclick = () => {
//       document.body.style.overflow = "hidden";
//       modal.classList.add("active");
//     };
//   });

//   modalBackdrop.onclick = () => {
//     document.body.style.overflow = "visible";
//     modal.classList.remove("active");
//   };

//   formClose.onclick = () => {
//     document.body.style.overflow = "visible";
//     modal.classList.remove("active");
//   };
// }

const newItem = document.querySelector(".panel-new");
const modal = document.querySelector(".modal");

if (newItem && modal) {
  const modalBackdrop = modal.querySelector(".modal-backdrop");
  const formClose = modal.querySelector(".form-close");

  newItem.onclick = () => {
    document.body.style.overflow = "hidden";
    modal.classList.add("active");
  };

  modalBackdrop.onclick = () => {
    document.body.style.overflow = "visible";
    modal.classList.remove("active");
  };

  formClose.onclick = () => {
    document.body.style.overflow = "visible";
    modal.classList.remove("active");
  };
}

const panelFilters = [].slice.apply(
  document.querySelectorAll(".panel-filter-type")
);
const products = [].slice.apply(
  document.querySelectorAll(".panel-category .category-item")
);

if (panelFilters && products) {
  panelFilters.forEach(f => {
    f.onchange = evt => {
      if (f.checked) {
        if (f.id == "todos") {
          products.forEach(p => p.classList.remove("hidden"));
          return;
        }

        products.forEach(p => {
          if (p.dataset.category == f.id) {
            p.classList.remove("hidden");
          } else {
            p.classList.add("hidden");
          }
        });
      }
    };
  });

  const formImage = document.querySelector(".form-image");
  const fileInput = document.querySelector('input[type="file"]');

  if (fileInput) {
    if (fileInput.files.length > 0) {
      const file = fileInput.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function(e) {
        formImage.src = e.target.result;
      };
    }

    fileInput.onchange = evt => {
      const file = evt.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function(e) {
        formImage.src = e.target.result;
      };
    };
  }
}
